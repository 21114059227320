import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_calendar_columns from '../../../../../../../common/src/assets/image/example_calendar_columns.png';
import template_options_calendar_columns from '../../../../../../../common/src/assets/image/template_options_calendar_columns.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        This template generates a calendar with days as rows and calendar data
        sources as columns. Any number of days can be generated. Each day will
        consist of multiple rows for containing its events.
      </p>
      <p>
        <Image alt="" src={example_calendar_columns} />
      </p>
      <h2>Template Settings</h2>
      <p>
        When creating a calendar using the Calendar Columns template, the
        following options are provided...
      </p>
      <Image
        alt=""
        src={template_options_calendar_columns}
        style={{ width: 448 }}
      />
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The start date can be typed into the box or selected using the
              drop-down arrow. This date will be the left-most column in the
              generated calendar.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>The total number of days to include in the output.</td>
          </tr>
          <tr>
            <td>
              <strong>Rows per day</strong>
            </td>
            <td>The number of rows for each day.</td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              The text behavior of items. Options are <strong>Wrap text</strong>
              , <strong>Don't wrap text</strong>, and{' '}
              <strong>Shrink text to fit</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background color for all-day items</strong>
            </td>
            <td>
              When checked, items that have times will have a transparent
              background and their text color will match the border color
              specified for the associated calendar data source or category.
              When unchecked, all items will have the background and text colors
              specified for the associated calendar or category.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time </strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>When checked, event or task end times will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item times will be in bold to help differentiate
              from item titles and locations.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show time on separate line</strong>
            </td>
            <td>
              When checked, item times will be shown on a separate line, above
              the title.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show location on separate line</strong>
            </td>
            <td>
              When checked, event or task locations will be shown on a separate
              line, below the title.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show description within items</strong>
            </td>
            <td>
              When checked, descriptions will be included in event and task
              blocks (if enough space is available).
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions in tooltips</strong>
            </td>
            <td>
              When checked, items will have tooltips showing descriptions. These
              tooltips will not be printed. They will be visible when hovering
              the mouse over items.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Day-Calendar-Columns"
      commentsId="commentsplus_post_1190_489"
      title="Calendar Columns Template"
      description="Generate a calendar with days as rows and calendar data sources as columns. Any number of days can be generated. Each day will consist of multiple rows for containing its events."
      keywords="day template, days as rows, calendars as columns, multiple day template"
      content={content}
    />
  );
};

export default Documentation;
